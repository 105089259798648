<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col>
          <h1>{{ customer.full_name }}</h1>
        </v-col>
        <v-col>
          <v-row align="center" justify="end" no-gutters>
            <v-btn
              depressed
              color="warning"
              @click="switchToBreederDialog.open = true"
            >
              <v-icon left>mdi-account-convert</v-icon>Switch Customer to
              Breeder
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <div>
        <v-row>
          <v-col cols="6">
            <v-card>
              <v-toolbar flat dark dense color="accent">
                <v-toolbar-title>Basic Details</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn depressed small @click="editCustomerDialog.open = true">
                  <v-icon left>mdi-pencil</v-icon>Edit
                </v-btn>
              </v-toolbar>
              <v-simple-table>
                <tbody>
                  <tr>
                    <th>First Name</th>
                    <td>{{ customer.first_name }}</td>
                  </tr>
                  <tr>
                    <th>Last Name</th>
                    <td>{{ customer.last_name }}</td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>{{ customer.email }}</td>
                  </tr>
                  <tr>
                    <th>Phone</th>
                    <td>{{ customer.phone || "N/A" }}</td>
                  </tr>
                  <tr>
                    <th>Marketing Opt In</th>
                    <td>
                      {{ customer.marketing_opt_in === 1 ? "Yes" : "No" }}
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card>
              <v-toolbar flat dark dense color="accent">
                <v-toolbar-title>Addresses</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn depressed small @click="addressDialog.open = true"
                  >Add New Address</v-btn
                >
              </v-toolbar>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    xl="6"
                    v-for="address in customer.addresses"
                    v-bind:key="address.id"
                  >
                    <v-card outlined>
                      <v-card-text>
                        <div
                          class="mb-3"
                          v-if="
                            customer.billing_address === address.id ||
                            customer.shipping_address === address.id
                          "
                        >
                          <v-chip
                            small
                            label
                            color="success"
                            v-if="customer.shipping_address === address.id"
                            class="mr-3"
                            >Delivery Address</v-chip
                          >
                          <v-chip
                            small
                            label
                            color="warning"
                            v-if="customer.billing_address === address.id"
                            >Billing Address</v-chip
                          >
                        </div>
                        <div>
                          <strong>{{ address.line_1 }}</strong>
                        </div>
                        <div v-if="address.line_2">{{ address.line_2 }}</div>
                        <div v-if="address.line_3">{{ address.line_3 }}</div>
                        <div>{{ address.city }}</div>
                        <div v-if="address.county">{{ address.county }}</div>
                        <div>{{ address.postcode }}</div>
                        <div v-if="address.country">{{ address.country }}</div>
                      </v-card-text>
                      <v-card-actions
                        v-if="
                          customer.billing_address !== address.id ||
                          customer.shipping_address !== address.id
                        "
                      >
                        <v-btn
                          small
                          outlined
                          color="success"
                          v-if="customer.shipping_address !== address.id"
                          :loading="changingDelivery === address.id"
                          @click="setAddress(address.id, 'delivery')"
                          >Set as Delivery</v-btn
                        >
                        <v-btn
                          small
                          outlined
                          color="warning"
                          v-if="customer.billing_address !== address.id"
                          :loading="changingBilling === address.id"
                          @click="setAddress(address.id, 'billing')"
                          >Set as Billing</v-btn
                        >
                      </v-card-actions>
                      <v-card-actions>
                        <v-btn
                          small
                          depressed
                          color="info"
                          @click="openEditAddress(address)"
                          >Edit</v-btn
                        >
                        <v-btn
                          small
                          depressed
                          color="error"
                          @click="deleteAddress(address)"
                          >Delete</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" xl="6">
            <v-card>
              <v-toolbar flat dark dense color="accent">
                <v-toolbar-title>Subscriptions</v-toolbar-title>
              </v-toolbar>
              <v-data-table
                :headers="subscriptionHeaders"
                :items="customer.subscriptions"
                no-data-text="No subscriptions found"
              >
                <template v-slot:item.identifier="{ item }">
                  <router-link
                    :to="{
                      name: 'module-paw-subscriptions-individual',
                      params: { subscriptionId: item.id },
                    }"
                    >{{ item.identifier }}</router-link
                  >
                </template>
                <template v-slot:item.frequency="{ item }">
                  {{ item.frequency_value }}
                  {{ item.frequency_unit }}
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        x-small
                        depressed
                        color="green lighten-4 green--text"
                        v-on="on"
                        :to="{
                          name: 'module-paw-subscriptions-individual',
                          params: { subscriptionId: item.id },
                        }"
                      >
                        <v-icon x-small>mdi-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>View</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
          <v-col cols="12" xl="6">
            <v-card>
              <v-toolbar flat dark dense color="accent">
                <v-toolbar-title>Deliveries</v-toolbar-title>
              </v-toolbar>
              <v-data-table
                :headers="deliveryHeaders"
                :items="deliveries"
                no-data-text="No deliveries found"
                :loading="deliveriesLoading"
              >
                <template v-slot:item.formattedId="{ item }">
                  <router-link
                    :to="{
                      name: 'module-paw-deliveries-individual',
                      params: { deliveryId: item.id },
                    }"
                    >{{ item.formattedId }}</router-link
                  >
                </template>
                <template v-slot:item.cost="{ item }"
                  >£{{ item.cost }}</template
                >
                <template v-slot:item.status="{ item }">
                  <v-chip
                    small
                    label
                    :color="getDeliveryStatusColor(item.delivery_status)"
                    >{{ getDeliveryStatusLabel(item.delivery_status) }}</v-chip
                  >
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        x-small
                        depressed
                        color="green lighten-4 green--text"
                        v-on="on"
                        :to="{
                          name: 'module-paw-deliveries-individual',
                          params: { deliveryId: item.id },
                        }"
                      >
                        <v-icon x-small>mdi-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>View</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
          <v-col cols="12" xl="6">
            <v-card>
              <v-toolbar flat dark dense color="accent">
                <v-toolbar-title>Discount Code Usage</v-toolbar-title>
              </v-toolbar>
              <v-data-table
                :headers="discountCodesHeaders"
                :items="customer.discount_codes"
                no-data-text="No discount Codes found"
                :loading="discountCodesLoading"
              >
              </v-data-table>
            </v-card>
          </v-col>

          <v-col cols="12" xl="6">
            <v-card>
              <v-toolbar flat dark dense color="accent">
                <v-toolbar-title>Orders</v-toolbar-title>
              </v-toolbar>
              <v-data-table
                :headers="tableOrdersHeaders"
                :items="customer.orders"
                no-data-text="No Orders can be found"
              >
                <template v-slot:item.id="{ item }">
                  <router-link
                    :to="{
                      name: 'module-paw-orders-individual',
                      params: { orderId: item.id },
                    }"
                    >#{{ item.identifier }}</router-link
                  >
                </template>
                <template v-slot:item.status="{ item }">
                  <v-chip
                    label
                    small
                    v-if="item.status === 'processing'"
                    color="blue"
                    text-color="white"
                    >Processing</v-chip
                  >
                  <v-chip
                    small
                    label
                    v-else-if="item.status === 'pending-payment'"
                    color="warning"
                    >Pending Payment</v-chip
                  >
                  <v-chip label small v-else-if="item.status === 'cancelled'"
                    >Cancelled</v-chip
                  >
                  <v-chip
                    label
                    small
                    v-else-if="item.status === 'complete'"
                    color="success"
                    >Complete</v-chip
                  >
                </template>
                <template v-slot:item.total="{ item }"
                  >£{{ item.total }}</template
                >
                <template v-slot:item.actions="{ item }">
                  <v-btn
                    x-small
                    depressed
                    color="green lighten-4 green--text"
                    :to="{
                      name: 'module-paw-orders-individual',
                      params: { orderId: item.id },
                    }"
                  >
                    <v-icon x-small left>mdi-eye</v-icon> View
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-container>
    <v-dialog v-model="deleteAddressDialog.open" max-width="400px">
      <v-card>
        <v-card-title class="headline"
          >Delete {{ deleteAddressDialog.addressLine1 }}</v-card-title
        >
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          <v-alert type="error" dense v-if="deleteAddressDialog.error">
            There was an error when attempting to delete this address. Please
            make sure that this address isn't either the Delivery or Billing
            Address. </v-alert
          >Are you sure you want to delete this address?
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="closeDeleteAddress()"
            >No, Cancel</v-btn
          >
          <v-btn
            color="accent"
            text
            @click="confirmDeleteAddress()"
            :loading="deleteAddressDialog.loading"
            >Yes, Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="addressDialog.open"
      max-width="600px"
      @click:outside="resetAddressForm()"
    >
      <v-card>
        <v-form @submit.prevent="saveAddress" method="post">
          <v-card-title>
            <span class="headline"
              >{{
                addressDialog.isEditing
                  ? "Edit " + addressDialog.address.line_1
                  : "Add New"
              }}
              Address</span
            >
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field
              label="Address Line 1"
              type="text"
              v-model="addressDialog.fields.line_1"
              required
              outlined
              dense
              :error="addressDialog.errors.hasOwnProperty('line_1')"
              :error-messages="addressDialog.errors['line_1']"
            ></v-text-field>
            <v-text-field
              label="Address Line 2"
              type="text"
              v-model="addressDialog.fields.line_2"
              required
              outlined
              dense
              :error="addressDialog.errors.hasOwnProperty('line_2')"
              :error-messages="addressDialog.errors['line_2']"
            ></v-text-field>
            <v-text-field
              label="Address Line 3"
              type="text"
              v-model="addressDialog.fields.line_3"
              required
              outlined
              dense
              :error="addressDialog.errors.hasOwnProperty('line_3')"
              :error-messages="addressDialog.errors['line_3']"
            ></v-text-field>
            <v-text-field
              label="City"
              type="text"
              v-model="addressDialog.fields.city"
              required
              outlined
              dense
              :error="addressDialog.errors.hasOwnProperty('city')"
              :error-messages="addressDialog.errors['city']"
            ></v-text-field>
            <v-text-field
              label="County"
              type="text"
              v-model="addressDialog.fields.county"
              required
              outlined
              dense
              :error="addressDialog.errors.hasOwnProperty('county')"
              :error-messages="addressDialog.errors['county']"
            ></v-text-field>
            <v-text-field
              label="Postcode"
              type="text"
              v-model="addressDialog.fields.postcode"
              required
              outlined
              dense
              :error="addressDialog.errors.hasOwnProperty('postcode')"
              :error-messages="addressDialog.errors['postcode']"
            ></v-text-field>
            <v-text-field
              label="Country"
              type="text"
              v-model="addressDialog.fields.country"
              required
              outlined
              dense
              :error="addressDialog.errors.hasOwnProperty('country')"
              :error-messages="addressDialog.errors['country']"
            ></v-text-field>
            <v-checkbox
              v-model="addressDialog.fields.is_shipping"
              label="Set as Delivery Address"
              hide-details
              v-if="!addressDialog.isEditing"
            ></v-checkbox>
            <v-checkbox
              v-model="addressDialog.fields.is_billing"
              label="Set as Billing Address"
              hide-details
              v-if="!addressDialog.isEditing"
            ></v-checkbox>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="resetAddressForm()"
              >Close</v-btn
            >
            <v-btn
              color="accent"
              text
              type="submit"
              :loading="addressDialog.loading"
              >Save</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editCustomerDialog.open" max-width="600px">
      <v-card>
        <v-form @submit.prevent="editCustomer" method="post">
          <v-card-title>
            <span class="headline">Edit {{ customer.full_name }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="6" class="pb-0">
                <v-text-field
                  label="First Name*"
                  type="text"
                  v-model="editCustomerDialog.first_name"
                  required
                  outlined
                  dense
                  :error="
                    editCustomerDialog.errors.hasOwnProperty('first_name')
                  "
                  :error-messages="editCustomerDialog.errors['first_name']"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="pb-0">
                <v-text-field
                  label="Last Name*"
                  type="text"
                  v-model="editCustomerDialog.last_name"
                  required
                  outlined
                  dense
                  :error="editCustomerDialog.errors.hasOwnProperty('last_name')"
                  :error-messages="editCustomerDialog.errors['last_name']"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-text-field
              label="Email*"
              type="email"
              v-model="editCustomerDialog.email"
              required
              outlined
              dense
              :error="editCustomerDialog.errors.hasOwnProperty('email')"
              :error-messages="editCustomerDialog.errors['email']"
            ></v-text-field>
            <v-text-field
              label="Phone"
              type="tel"
              v-model="editCustomerDialog.phone"
              required
              outlined
              dense
              :error="editCustomerDialog.errors.hasOwnProperty('phone')"
              :error-messages="editCustomerDialog.errors['phone']"
            ></v-text-field>
            <v-checkbox
              v-model="editCustomerDialog.marketing_opt_in"
              label="Marketing Opt In"
              hide-details
              class="mt-0"
            ></v-checkbox>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              text
              @click="editCustomerDialog.open = false"
              >Close</v-btn
            >
            <v-btn
              color="accent"
              text
              type="submit"
              :loading="editCustomerDialog.loading"
              >Save</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="switchToBreederDialog.open" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline"
            >Switch {{ customer.full_name }} To A Breeder</span
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-4">
          <p>
            Are you sure you want to switch {{ customer.full_name }} to be a
            Breeder?
          </p>
          <p>If yes, then please complete this form.</p>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Business/Kennel Name"
                type="text"
                v-model="switchToBreederDialog.fields.business_name"
                required
                outlined
                dense
                :error="
                  switchToBreederDialog.errors.hasOwnProperty('business_name')
                "
                :error-messages="switchToBreederDialog.errors['business_name']"
              ></v-text-field>
              <v-text-field
                label="Date Joined"
                v-model="switchToBreederDialog.fields.joined_at"
                type="date"
                :max="max"
                outlined
                dense
                :error="
                  switchToBreederDialog.errors.hasOwnProperty('joined_at')
                "
                :error-messages="switchToBreederDialog.errors['joined_at']"
              ></v-text-field>
              <v-text-field
                label="Available Credit"
                type="text"
                v-model="switchToBreederDialog.fields.available_credit"
                required
                outlined
                dense
                :error="
                  switchToBreederDialog.errors.hasOwnProperty(
                    'available_credit'
                  )
                "
                :error-messages="
                  switchToBreederDialog.errors['available_credit']
                "
              ></v-text-field>
            </v-col>
          </v-row>
          <v-text-field
            label="Lifetime Credit Earned"
            type="text"
            v-model="switchToBreederDialog.fields.lifetime_credit"
            required
            outlined
            dense
            :error="
              switchToBreederDialog.errors.hasOwnProperty('lifetime_credit')
            "
            :error-messages="switchToBreederDialog.errors['lifetime_credit']"
          ></v-text-field>
          <v-row>
            <v-col cols="2">
              <v-select
                label="Type"
                v-model="switchToBreederDialog.fields.breeder_credited_type"
                :items="types"
                dense
                outlined
                background-color="white"
                :error="
                  switchToBreederDialog.errors.hasOwnProperty(
                    'breeder_credited_type'
                  )
                "
                :error-messages="
                  switchToBreederDialog.errors['breeder_credited_type']
                "
              ></v-select>
            </v-col>
            <v-col cols="10">
              <v-text-field
                label="Breeder Credit Per Code Used"
                type="text"
                v-model="switchToBreederDialog.fields.breeder_credited_amount"
                required
                outlined
                dense
                :error="
                  switchToBreederDialog.errors.hasOwnProperty(
                    'breeder_credited_amount'
                  )
                "
                :error-messages="
                  switchToBreederDialog.errors['breeder_credited_amount']
                "
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              <v-select
                label="Type"
                v-model="switchToBreederDialog.fields.customer_discount_type"
                :items="types"
                outlined
                dense
                background-color="white"
                :error="
                  switchToBreederDialog.errors.hasOwnProperty(
                    'customer_discount_type'
                  )
                "
                :error-messages="
                  switchToBreederDialog.errors['customer_discount_type']
                "
              ></v-select>
            </v-col>
            <v-col cols="10">
              <v-text-field
                label="Customer Discount"
                type="text"
                v-model="switchToBreederDialog.fields.customer_discount_amount"
                required
                outlined
                dense
                :error="
                  switchToBreederDialog.errors.hasOwnProperty(
                    'customer_discount_amount'
                  )
                "
                :error-messages="
                  switchToBreederDialog.errors['customer_discount_amount']
                "
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              <v-select
                label="Type"
                v-model="switchToBreederDialog.fields.breeder_discount_type"
                :items="types"
                outlined
                dense
                background-color="white"
                :error="
                  switchToBreederDialog.errors.hasOwnProperty(
                    'breeder_discount_type'
                  )
                "
                :error-messages="
                  switchToBreederDialog.errors['breeder_discount_type']
                "
              ></v-select>
            </v-col>
            <v-col cols="10">
              <v-text-field
                label="Breeder Discount"
                type="text"
                v-model="switchToBreederDialog.fields.breeder_discount_amount"
                outlined
                dense
                :error="
                  switchToBreederDialog.errors.hasOwnProperty(
                    'breeder_discount_amount'
                  )
                "
                :error-messages="
                  switchToBreederDialog.errors['breeder_discount_amount']
                "
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            @click="switchToBreederDialog.open = false"
            >Close</v-btn
          >
          <v-btn
            color="accent"
            text
            @click="confirmSwitchBreeder()"
            :loading="switchToBreederDialog.loading"
          >
            Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar.open" :timeout="snackbar.timeout">
      {{ snackbar.text }}
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      max: new Date().toISOString().slice(0, 10),
      types: [
        { text: "£", value: "number" },
        { text: "%", value: "percent" },
      ],
      deliveries: [],
      deliveriesLoading: true,
      discountCodesLoading: false,
      tableOrdersHeaders: [
        { text: "Order", value: "id" },
        { text: "Date Placed", value: "formatted_dates.created_at" },
        { text: "Status", value: "status" },
        { text: "Total", value: "total" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      breadcrumbs: [
        {
          text: "Paw Dog Food",
          disabled: false,
          to: { name: "app-dashboard", params: { id: this.$route.params.id } },
        },
        {
          text: "Customers",
          disabled: false,
          exact: true,
          to: {
            name: "module-paw-customers",
            params: { id: this.$route.params.id },
          },
        },
      ],
      subscriptionHeaders: [
        { text: "Ref No", value: "identifier" },
        { text: "Frequency", value: "frequency" },
        { text: "Status", value: "status" },
        { text: "Start Date", value: "formatted_dates.start_date" },
        { text: "Actions", value: "actions", align: "right" },
      ],
      deliveryHeaders: [
        { text: "Ref No", value: "formattedId" },
        { text: "Payment Date", value: "formatted_dates.process_date" },
        {
          text: "Estimated Delivery Date",
          value: "formatted_dates.estimated_delivery",
        },
        { text: "Cost", value: "cost" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions", align: "right" },
      ],
      discountCodesHeaders: [
        { text: "Code", value: "discount_code.code" },
        { text: "Date Used", value: "formatted_dates.date_used" },
      ],
      deliveryStatuses: [
        { value: "scheduled", label: "Scheduled", color: "blue white--text" },
        {
          value: "manually_paused",
          label: "Manually Paused",
          color: "red white--text",
        },
        {
          value: "payment_failed",
          label: "Payment Failed",
          color: "red white--text",
        },
        {
          value: "paid_dispatched",
          label: "Paid & Dispatched",
          color: "yellow white--text",
        },
        { value: "complete", label: "Complete", color: "green white--text" },
        { value: "cancelled", label: "Cancelled", color: "grey" },
        { value: "refunded", label: "Refunded", color: "red white--text" },
      ],
      deleteAddressDialog: {
        open: false,
        error: false,
        addressLine1: "",
        addressId: null,
        loading: false,
      },
      addressDialog: {
        open: false,
        errors: {},
        fields: {
          line_1: "",
          line_2: "",
          line_3: "",
          city: "",
          county: "",
          postcode: "",
          country: "",
          is_shipping: false,
          is_billing: false,
        },
        loading: false,
        isEditing: false,
        address: {},
      },
      editCustomerDialog: {
        open: false,
        errors: {},
        first_name: "",
        last_name: "",
        email: "",
        phone: null,
        marketing_opt_in: false,
        loading: false,
      },
      switchToBreederDialog: {
        open: false,
        fields: {
          joined_at: null,
          available_credit: "",
          lifetime_credit: "",
          customer_discount: "",
          customer_discount_type: "number",
          breeder_credited_type: "number",
          breeder_credited_amount: "",
          breeder_discount: "",
          breeder_discount_type: "number",
          business_name: "",
        },
        loading: false,
        errors: {},
      },
      snackbar: {
        open: false,
        text: "",
        timeout: 4000,
      },
      changingDelivery: null,
      changingBilling: null,
    };
  },

  computed: {
    customer() {
      return this.$store.state.paw["customer"];
    },
  },

  mounted() {
    this.formatDeliveries();

    this.editCustomerDialog.first_name = this.customer.first_name;
    this.editCustomerDialog.last_name = this.customer.last_name;
    this.editCustomerDialog.email = this.customer.email;
    this.editCustomerDialog.phone = this.customer.phone;
    this.editCustomerDialog.marketing_opt_in = this.customer.marketing_opt_in;
  },

  watch: {
    customer: function (customer) {
      this.editCustomerDialog.first_name = customer.first_name;
      this.editCustomerDialog.last_name = customer.last_name;
      this.editCustomerDialog.email = customer.email;
      this.editCustomerDialog.phone = customer.phone;
      this.editCustomerDialog.marketing_opt_in = customer.marketing_opt_in;
    },
  },

  methods: {
    confirmSwitchBreeder: function () {
      this.switchToBreederDialog.loading = true;
      this.switchToBreederDialog.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: false,
        fields: this.switchToBreederDialog.fields,
      };

      payload.fields.customer_id = this.$route.params.customerId;

      this.$store
        .dispatch("paw/saveBreeder", {
          ...payload,
        })
        .then(() => {
          this.switchToBreederDialog = {
            open: false,
            errors: {},
            fields: {
              joined_at: null,
              available_credit: "",
              lifetime_credit: "",
              customer_discount: "",
              customer_discount_type: "number",
              breeder_credited_type: "number",
              breeder_credited_amount: "",
              breeder_discount: "",
              breeder_discount_type: "number",
              business_name: "",
            },
            loading: false,
            isEditing: false,
          };
          this.$router.push({
            name: "module-paw-breeders",
          });
        })
        .catch((err) => {
          this.switchToBreederDialog.errors = err.response.data.errors;
          this.switchToBreederDialog.loading = false;
        });
    },

    formatDeliveries: function () {
      const subscriptions = this.customer.subscriptions;
      let deliveries = [];

      subscriptions.forEach((s) => {
        s.deliveries.forEach((delivery) => {
          let d = delivery;
          d.formattedId = "";
          d.formattedId = s.identifier.concat(delivery.identifier);
          deliveries.push(d);
        });
      });

      this.deliveries = deliveries;
      this.deliveriesLoading = false;
    },
    getDeliveryStatusLabel: function (value) {
      const status = this.deliveryStatuses.find((s) => s.value === value);

      return status.label;
    },
    getDeliveryStatusColor: function (value) {
      const status = this.deliveryStatuses.find((s) => s.value === value);

      return status.color;
    },
    deleteAddress: function (address) {
      this.deleteAddressDialog.open = true;
      this.deleteAddressDialog.addressLine1 = address.line_1;
      this.deleteAddressDialog.addressId = address.id;
    },
    closeDeleteAddress: function () {
      this.deleteAddressDialog = {
        open: false,
        error: false,
        addressLine1: "",
        addressId: null,
        loading: false,
      };
    },
    confirmDeleteAddress: function () {
      const addressId = this.deleteAddressDialog.addressId;
      const appId = this.$route.params.id;
      const customerId = parseInt(this.$route.params.customerId);
      this.deleteAddressDialog.loading = true;

      this.$store
        .dispatch("paw/deleteAddress", { appId, addressId, customerId })
        .then(() => {
          this.deleteAddressDialog = {
            open: false,
            error: false,
            addressLine1: "",
            addressId: null,
            loading: false,
          };
        })
        .catch(() => {
          this.deleteAddressDialog.loading = false;
          this.deleteAddressDialog.error = true;
        });
    },
    setAddress: function (addressId, type) {
      if (type === "delivery") {
        this.changingDelivery = addressId;
      } else {
        this.changingBilling = addressId;
      }

      const appId = this.$route.params.id;
      const customerId = this.$route.params.customerId;

      this.$store
        .dispatch("paw/setAddress", { appId, addressId, customerId, type })
        .then(() => {
          this.changingBilling = null;
          this.changingDelivery = null;
        })
        .catch(() => {
          this.changingBilling = null;
          this.changingDelivery = null;
        });
    },
    saveAddress: function () {
      const appId = this.$route.params.id;
      const customerId = this.$route.params.customerId;
      this.addressDialog.loading = true;
      this.addressDialog.errors = {};

      let payload = {
        appId,
        customerId,
        ...this.addressDialog.fields,
        isEditing: false,
      };

      if (this.addressDialog.isEditing) {
        payload.isEditing = true;
        payload.addressId = this.addressDialog.address.id;
      }

      this.$store
        .dispatch("paw/saveAddress", {
          ...payload,
        })
        .then(() => {
          this.resetAddressForm();
        })
        .catch((err) => {
          this.addressDialog.errors = err.response.data.errors;
          this.addressDialog.loading = false;
        });
    },
    openEditAddress: function (address) {
      this.addressDialog.fields.line_1 = address.line_1;
      this.addressDialog.fields.line_2 = address.line_2;
      this.addressDialog.fields.line_3 = address.line_3;
      this.addressDialog.fields.city = address.city;
      this.addressDialog.fields.county = address.county;
      this.addressDialog.fields.postcode = address.postcode;
      this.addressDialog.fields.country = address.country;

      if (this.customer.shipping_address === address.id) {
        this.addressDialog.fields.is_shipping = true;
      }
      if (this.customer.billing_address === address.id) {
        this.addressDialog.fields.is_billing = true;
      }

      this.addressDialog.isEditing = true;
      this.addressDialog.address = address;
      this.addressDialog.open = true;
    },
    editCustomer: function () {
      const appId = this.$route.params.id;
      const customerId = this.$route.params.customerId;
      this.editCustomerDialog.loading = true;
      this.editCustomerDialog.errors = {};

      this.$store
        .dispatch("paw/editCustomer", {
          appId,
          customerId,
          ...this.editCustomerDialog,
        })
        .then(() => {
          (this.editCustomerDialog.open = false),
            (this.editCustomerDialog.errors = {}),
            (this.editCustomerDialog.loading = false);
        })
        .catch((err) => {
          this.editCustomerDialog.errors = err.response.data.errors;
          this.editCustomerDialog.loading = false;
        });
    },
    resetAddressForm: function () {
      this.addressDialog = {
        open: false,
        errors: {},
        fields: {
          line_1: "",
          line_2: "",
          line_3: "",
          city: "",
          county: "",
          postcode: "",
          country: "",
          is_shipping: false,
          is_billing: false,
        },
        loading: false,
        isEditing: false,
        address: {},
      };
    },
  },
};
</script>
